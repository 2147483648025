/**
 * 判断交易的准确性 return false交易成功  true交易失败
 * @param {交易执行器} Actuators
 * @param {交易type} ty
 */
const tradeAccuracy = function(Actuators: string, ty: number, execer?: any) {
    if (
        (Actuators == "none" || Actuators.substring(0, 5) == "user.") &&
        !execer
    ) {
        return false;
    } else {
        if (ty == 1) {
            return true;
        } else {
            return false;
        }
    }
};

const nFormatter = (num: number, digits = 2) => {
    const si = [
        {
            value: 1,
            symbol: "",
        },
        {
            value: 1e3,
            symbol: "K",
        },
        {
            value: 1e6,
            symbol: "M",
        },
        {
            value: 1e9,
            symbol: "G",
        },
        {
            value: 1e12,
            symbol: "T",
        },
        {
            value: 1e15,
            symbol: "P",
        },
        {
            value: 1e18,
            symbol: "E",
        },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export { tradeAccuracy, nFormatter };

export const registerTimeoutToTimerObj = (
    TimerObj: { [key: string]: number },
    func: () => any,
    delay: number
) => {
    TimerObj[func.name] = window.setTimeout(() => {
        func();
    }, delay);
};

export const clearTimeoutFromTimerObj = (TimerObj: {
    [key: string]: number;
}) => {
    Object.values(TimerObj).forEach((timer) => window.clearTimeout(timer));
};

/**
 * 检查是否为eth地址
 *
 * @export
 * @param {*} adr
 */
export function checkIsEthAdr(adr: string) {
    const addr = adr.slice(0, 2) == "0x" ? adr.slice(2, adr.length) : adr;
    const eg = /^[A-Fa-f0-9]+$/;
    if (eg.test(addr)) return adr.toLocaleLowerCase();
    else return adr;
}
